import { h } from 'preact';
import { Route, Router } from 'preact-router';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from 'src/routes/home/home';

const App = () => (
  <div id="app">
    <Header />
    <Router>
        <Route path="/:gameId?" component={Home} />
    </Router>
    </div>
);

export default App;
