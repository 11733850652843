import { h } from 'preact';
import style from 'src/routes/home/home.scss';

const Home = ( { gameId }: { gameId: string} ) => {
  console.log(gameId);
  return (
    <div class={style.home}>
      <h1 class={style.h1}>Home</h1>
      <p>This is the Home component.</p>
    </div>
  )
};

export default Home;
